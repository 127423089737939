import React, {useEffect, useState} from "react";
import {graphql, Link, navigate} from 'gatsby';
import {Button, Form, Input, notification} from "antd";
import SecureLS from "secure-ls";
import Api from "../../components/common/Api";
import '../../styles/index.css';
import '../../styles/tailwind.css';
import Auth from "../../components/layouts/Auth";
import SEO from "../../components/seo";
import ForgotPasswordModal from "../../components/Modals/ForgotPasswordModal";

export default function Login() {
  const [forgotPasswordModal, setForgotPasswordModal] = useState()
  let userId = 0;
  let userRole = '';
  if(typeof localStorage !== 'undefined'){
    const ls = new SecureLS({encodingType: 'aes'});
    userId = ls.get('secret_key').userId
    userRole = ls.get('secret_key').userRole
  }
  
  const loadData = () => {
    //do nothing
    if(typeof localStorage !== undefined){
      window.location.reload()
    }
  }
  
  useEffect(() => {
    if(userId && userRole !== 'guest_customer'){
      navigate("/")
    }
    else {
      if(typeof localStorage !== 'undefined') {
        const ls = new SecureLS({encodingType: 'aes'});
        ls.removeAll();
      }
    }
  }, [])
  
  const onFinish = values => {
    const openErrorUserExistNotificationWithIcon = type => {
      notification[type]({
        message: 'Giriş Başarısız!',
        description:
            'Maalesef böyle bir e-mail sistemimizde kayıtlı değil, lütfen kayıt olunuz!',
      });
    };
    const openFalseInformationNotificationWithIcon = type => {
      notification[type]({
        message: 'Giriş Başarısız!',
        description:
            'Yanlış bir e-mail ya da şifre girdiniz. Lütfen kontrol ediniz!',
      });
    };
    const openSuccessNotificationWithIcon = type => {
      notification[type]({
        message: 'Başarıyla Giriş Yapıldı!',
        description:
            'Egzersiz yapmaya başlayabilirsiniz!',
      });
    };
    const openSuccessButNoActivatedAccNotificationWithIcon = type => {
      notification[type]({
        message: 'Başarıyla Giriş Yapıldı Ancak Hesabınız Onaylı Değil!',
        description:
            'Hesabınızı onaylama işlemini gerçekleştirmeden egzersiz yapamazsınız. E-mail adresinizi kontrol edin ve onaylama işlemini gerçekleştirin!',
      });
    };
    Api.logIn(values)
        .then((response) => {
          if(response.data.code === 3){
            openErrorUserExistNotificationWithIcon('error')
          }
          else if(response.data.code === 2){
            openFalseInformationNotificationWithIcon('error')
          }
          else if(response.data.code === 1){
            if (response.data.userIsActivated === '0'){
              openSuccessButNoActivatedAccNotificationWithIcon('success')
            }
            else{
              openSuccessNotificationWithIcon('success')
            }
            let data = {
              userRole : response.data.userRole,
              userId : response.data.userId,
              userName : response.data.userName,
              userIsActivated: response.data.userIsActivated
            }
            if(typeof localStorage !== 'undefined'){
              const ls = new SecureLS({encodingType: 'aes'});
              ls.set('secret_key', data); // set key1
            }
            const ls = new SecureLS({encodingType: 'aes'});
            setTimeout(() => {
              navigate("/")
            }, 3000);
          }
        })
        .catch((err) => console.log(err));
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  
  return (
      <>
        <SEO
            title="Giriş Yap"
            description="Bu sayfadan egzersiz kütüphanesine giriş yapabilirsiniz."
        />
      <Auth>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-600 text-sm font-bold">
                      Hâla daha bir hesabın yok mu?
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    <Link to="/auth/register">
                      <button
                          className="bg-white text-gray-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                          type="button"
                      >
                        <i className="fas fa-arrow-alt-circle-up">
                          
                        </i> Kayıt Ol <i className="fas fa-arrow-alt-circle-up">
                        
                      </i>
                      </button>
                    </Link>
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div className="text-gray-500 text-center mb-3 font-bold">
                    <small>Bilgilerin ile giriş yap!</small>
                  </div>
                  <Form
                      name="loginForm"
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                  >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Lütfen e-mailinizi giriniz!' }]}
                    >
                      <Input />
                    </Form.Item>
  
                    <Form.Item
                        label="Şifre"
                        name="password"
                        rules={[{ required: true, message: 'Lütfen şifrenizi giriniz!' }]}
                    >
                      <Input.Password />
                    </Form.Item>
  
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Giriş Yap
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
              <div className="flex flex-wrap mt-6 relative">
                <div className="w-1/2">
                  <Button onClick={() => setForgotPasswordModal(true)}>
                    Parolanı mı unuttun?
                  </Button>
                  <ForgotPasswordModal
                      forgotPasswordModal={forgotPasswordModal}
                      visibleState={(isReload) => {
                        isReload && loadData();
                        setForgotPasswordModal(null);
                      }}
                  />
                </div>
                <div className="w-1/2 text-right">
                  <Link to="/auth/register" className="text-gray-300">
                    <small>Yeni hesap oluştur!</small>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Auth>
      </>
  );
}
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
import React, { useState } from "react";
import {Modal, Button, Form, notification, Input} from 'antd';
import 'antd/dist/antd.css';
import Api from "../common/Api";

function ForgotPasswordModal (props) {
    const {forgotPasswordModal, visibleState} = props;
    const [loading, setLoading] = useState(false);
    const [verifyCode, setVerifyCode] = useState(false);
    const [verifyPasswordLoading, setVerifyPasswordLoading] = useState(false)
    const [mailCode, setMailCode] = useState();
    
    const onFinish = values => {
        const openSuccessSendMailNotificationWithIcon = type => {
            notification[type]({
                message: 'Onay Maili Gönderildi!',
                description:
                    'Başarıyla email gönderildi. Lütfen mailinizi kontrol ediniz ve gelen kodu yazınız!',
            });
        };
        const openErrorSendMailNotificationWithIcon = type => {
            notification[type]({
                message: 'Hata Var!',
                description:
                    'Maalesef sistemimizde böyle bir Email yok!',
            });
        };
        
        Api.forgotPasswordVerifyMailSend({
            ...values
        }).then((response) => {
            if(response.data.code === 1){
                openSuccessSendMailNotificationWithIcon('success')
                setMailCode(response.data.data)
                setLoading(false)
                setVerifyCode(true)
            }else{
                openErrorSendMailNotificationWithIcon('error')
                setLoading(false)
            }
        })
            .catch((err) => console.log( err.response.request ));
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onFinishVerifyPassword = values => {
        const openSuccessSendMailNotificationWithIcon = type => {
            notification[type]({
                message: 'Kodunuz Onaylandı!',
                description:
                    'Kodunuz onaylandı. Lütfen yeni şifrenizi yazınız!',
            });
        };
        const openErrorSendMailNotificationWithIcon = type => {
            notification[type]({
                message: 'Hata Var!',
                description:
                    'Hatalı kod girdiniz. İşleminiz iptal edildi!',
            });
        };
        
        if(mailCode === parseInt(values.forgot_password_code)){
            openSuccessSendMailNotificationWithIcon('success')
            setVerifyPasswordLoading(false)
            Api.forgotPasswordResetPassword({
                ...values
            }).then((response) => {
                if(response.data.code === 1){
                    visibleState(true);
                }else{
                    visibleState(true);
                }
            })
                .catch((err) => console.log( err.response.request ));
        }else{
            openErrorSendMailNotificationWithIcon('error')
            visibleState(true);
        }
    };

    const onFinishVerifyPasswordFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    
    return(
        <>
            <Modal
                title={"Şifre Sıfırlama Ekranı"}
                visible={!!forgotPasswordModal}
                onCancel={props.visibleState}
                footer={null}
            >
                {verifyCode === true
                    ?<Form
                        name="verifyPasswordForm"
                        onFinish={onFinishVerifyPassword}
                        onFinishFailed={onFinishVerifyPasswordFailed}
                    >
                        <Form.Item
                            rules={[{ required: true, message: 'Lütfen mailinize gönderdiğimiz kodu giriniz!' }]}
                            name="forgot_password_code"
                            label="Onay Kodu"
                        >
                            {verifyPasswordLoading
                                ? <Input disabled={true}/>
                                : <Input disabled={false}/>
                            }
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={verifyPasswordLoading}
                                onClick={() => setVerifyPasswordLoading(true)}
                            >
                                İstek Gönder!
                            </Button>
                        </Form.Item>
                    </Form>
                    :<Form
                        name="forgotPasswordForm"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            rules={[{ type: 'email', required: true, message: 'Lütfen geçerli bir email giriniz!' }]}
                            name="email"
                            label="Email"
                        >
                            {loading
                                ? <Input disabled={true}/>
                                : <Input disabled={false}/>
                            }
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                onClick={() => setLoading(true)}
                            >
                                İstek Gönder!
                            </Button>
                        </Form.Item>
                    </Form>
                }
                
            </Modal>
        </>
    )
}

export default ForgotPasswordModal;
import React from "react";
import Navbar from "../Navbars/AuthNavbar";
import FooterSmall from "../Footers/FooterSmall";
import BGImg from '../../img/register_bg_2.png'

export default function Auth({children}) {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + BGImg + ")",
            }}
          >
              {children}
          </div>
        </section>
      </main>
        {/*<FooterSmall absolute />*/}
    </>
  );
}
